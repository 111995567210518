// IMPORTANT: The 'amcharts' scripts order is relevant.
// -- START amcharts/amstock --
import 'amcharts3';
import 'amcharts3/amcharts/serial';
import 'amcharts3/amcharts/gantt';
import 'amstock3/amcharts/amstock';
// -- END amcharts/amstock --

// NOTE: Import the rest of the third party libraries in the alphabetical order.
// -- START Third party libraries --
import '@uirouter/angularjs';
import 'angular';
import 'angular-animate';
import 'angular-cookies';
import 'angular-jwt';
import 'angular-material';
import 'angular-material/angular-material.scss';
import 'angular-messages';
import 'angular-sanitize';
import 'angular-simple-logger';
import 'angular-toastr';
import 'angular-toastr/dist/angular-toastr.css';
import 'angular-tooltips';
import 'angular-tooltips/dist/angular-tooltips';
import 'angular-tooltips/dist/angular-tooltips.css';
import 'angularjs-slider';
import 'angularjs-slider/dist/rzslider.css';
import 'auth0-js';
import 'checklist-model';
import 'datejs';
import 'js-xlsx';
import 'ng-csv';
import 'ng-file-upload';
import 'ng-i18next';
import 'reflect-metadata';
// -- END Third party libraries --

import './index.module';
import './index.run';
import './inversify.config';

import './app/index.scss';
import './app/core/scss/global.scss';

// commented out .css imports had variable issues - fixable, but not sure how many we're actually using anyway
import './app/_components/common/run-data-capture.scss';
import './app/_components/nutrients-summary-chart.scss';
import './app/_components/nutrients-summary-table.scss';
import './app/_components/nutrients/nutrients-form.scss';
import './app/_components/select-account-sites.scss';
import './app/_components/site-irrigation-summary-chart.scss';
import './app/_components/waterusage-summary-chart.scss';
import './app/_components/weather-summary-chart.scss';
import './app/_components/weather-summary-table.scss';
import './app/core/directives/ms-navigation/ms-navigation.scss';
import './app/core/directives/ms-responsive-table/ms-responsive-table.scss';
import './app/core/directives/ms-splash-screen/ms-splash-screen.scss';
import './app/core/directives/ms-stepper/ms-stepper.scss';
import './app/core/directives/ms-stepper/templates/horizontal/horizontal.scss';
import './app/core/directives/ms-stepper/templates/vertical/vertical.scss';
import './app/core/directives/ms-theme-options/ms-theme-options.scss';
import './app/core/directives/ms-widget/ms-widget.scss';
import './app/core/scss/partials/angular-material-extend.scss';
import './app/core/scss/partials/animations.scss';
import './app/core/scss/partials/colors.scss';
import './app/core/scss/partials/helpers.scss';
import './app/core/scss/partials/icons.scss';
// import './app/core/scss/partials/layouts-page.scss'
// import './app/core/scss/partials/layouts-template.scss'
// import './app/core/scss/partials/material.scss'
// import './app/core/scss/partials/plugins/angular-moment-picker.scss'
import './app/core/scss/partials/plugins/datatable.scss';
import './app/core/scss/partials/plugins/text-angular.scss';
import './app/core/scss/partials/print.scss';
import './app/core/scss/partials/reset.scss';
// import './app/core/scss/partials/typography.scss'
import './app/core/scss/partials/variables.scss';
import './app/navigation/navigation.scss';
import './app/pages/account/components/nutrient-application.scss';
import './app/pages/account/components/add-site-fertiliser-dialog.scss';
import './app/pages/account/views/dashboard/dashboard-health-index-table.scss';
import './app/pages/account/views/dashboard/dashboard.scss';
import './app/pages/account/views/equipment/equipment.scss';
import './app/pages/account/views/equipment/components/infra-flow-chart.scss';
import './app/pages/account/views/equipment/components/infra-flow-table.scss';
import './app/pages/account/views/equipment/components/infra-depth-table.scss';
import './app/pages/account/views/equipment/components/infra-depth-chart.scss';
import './app/pages/account/views/equipment/settings/data-capture-dialog.scss';
import './app/pages/account/views/equipments/equipments.scss';
import './app/pages/account/views/group/group.scss';
import './app/pages/account/views/groups/groups.scss';
import './app/pages/water/irrigation-override/irrigation-override.scss';
import './app/pages/account/views/site/map/map.scss';
import './app/pages/account/views/site/settingsTabs/crop/cropgraph-dialog.scss';
import './app/pages/account/views/site/settingsTabs/kcvi/addAlgorithm-dialog.scss';
import './app/pages/account/views/site/settingsTabs/kcvi/addKcviUse-dialog.scss';
import './app/pages/account/views/site/settingsTabs/kcvi/kcvi-settings.scss';
import './app/pages/account/views/site/settingsTabs/kcvi/nominateHealthAssets-dialog.scss';
import './app/pages/account/views/site/settingsTabs/sitearea-dialog.scss';
import './app/pages/account/views/site/settingsTabs/weather/addWeatherStation-dialog.scss';
import './app/pages/account/views/site/settingsTabs/weather/copy-weather-dialog.scss';
import './app/pages/account/views/site/settingsTabs/weather/site-settings-weather.scss';
import './app/pages/account/views/site/site.scss';
import './app/pages/account/views/site/waterTabs/site-water-applied.scss';
import './app/pages/account/views/site/waterTabs/siteHistoryChartHelp.-dialog.scss';
import './app/pages/account/views/sites/sites.scss';
import './app/pages/account/views/infrastructure/components/settings-depth-tab.scss';
import './app/pages/account/views/infrastructure/depth.scss';
import './app/pages/account/views/infrastructure/equipment-dialog.scss';
import './app/pages/account/views/infrastructure/equipments.scss';
import './app/pages/account/views/infrastructure/infrastructure.scss';
import './app/pages/account/views/infrastructures/infrastructures.scss';
import './app/pages/administration/administration.scss';
import './app/pages/administration/views/account-details/account-defaults-dialog.scss';
import './app/pages/administration/views/account-details/account-details.scss';
import './app/pages/administration/views/account-details/accountrecalculateSMB-dialog.scss';
import './app/pages/administration/views/account-details/data-capture/configure-account-assets.scss';
import './app/pages/administration/views/account-details/data-capture/data-capture-dialog.scss';
import './app/pages/administration/views/account-details/kcvi/configureKcvi-options.scss';
import './app/pages/administration/views/account-details/kcvi/configureKcvi-sites.scss';
import './app/pages/administration/views/account-details/kcvi/configureSitesKcvi-dialog.scss';
import './app/pages/administration/views/account-users/account-users.scss';
import './app/pages/administration/views/account-users/new-account-user-dialog.scss';
import './app/pages/administration/views/contacts/contacts.scss';
import './app/pages/administration/views/contacts/dialogs/contact-dialog.scss';
import './app/pages/administration/views/contacts/priceList.scss';
import './app/pages/auth/login/login.scss';
import './app/pages/crops/crops.scss';
import './app/pages/crops/cycles/components/crop-cycle-option.scss';
import './app/pages/crops/cycles/crop-cycles-chart/crop-cycles-chart.scss';
import './app/pages/crops/cycles/cycles.scss';
import './app/pages/crops/cycles/dialogs/crop-cycle-dialog.scss';
import './app/pages/crops/cycles/dialogs/crop-cycle-results-dialog.scss';
import './app/pages/nutrients/analytes/analytes.scss';
import './app/pages/nutrients/fertiliser/fertilisers.scss';
import './app/pages/nutrients/fertiliser/specificGravityCalculator-dialog.scss';
import './app/pages/nutrients/nutrients.scss';
import './app/pages/nutrients/programs/program-details/program-details.scss';
import './app/pages/nutrients/programs/program-details/tabs/fertiliser-distribution.component.scss';
import './app/pages/nutrients/programs/program-details/tabs/general-detail.component.scss';
import './app/pages/nutrients/profiles/profiles.scss';
import './app/pages/nutrients/programs/programs.scss';
import './app/pages/nutrients/sampling/samples.scss';
import './app/pages/nutrients/tankmix/tankmixes.scss';
import './app/pages/profile/profile.scss';
import './app/pages/profile/tabs/profile-uom.scss';
import './app/pages/water/water.scss';
import './app/pages/water/allocation/allocation.scss';
import './app/pages/water/allocation/components/allocation-budget-tab.scss';
import './app/pages/water/allocation/components/allocation-suballocation-tab.scss';
import './app/pages/water/allocation/components/allocation-summary-info.scss';
import './app/pages/water/allocation/components/allocation-summary-tab.scss';
import './app/pages/water/allocation/components/allocation-transactions-tab.scss';
import './app/pages/water/allocations/allocation-dialog.scss';
import './app/pages/water/allocations/allocations.scss';
import './app/pages/water/allocations/copyallocation-dialog.scss';
import './app/pages/water/irrigation-controller/irrigation-controller.scss';
import './app/pages/water/irrigation-plan/addOverride-dialog.scss';
import './app/pages/water/irrigation-plan/addSiteInPlan-dialog.scss';
import './app/pages/water/irrigation-plan/irrigation-plan.scss';
import './app/pages/water/irrigation-plan/irrigationHelp-dialog.scss';
import './app/pages/water/irrigation-plan/irrigationUpdateMultiSites-dialog.scss';
import './app/pages/water/irrigation-plan/irrigation_logs.scss';
import './app/pages/water/irrigation-plan/irrigation_planning.scss';
import './app/pages/water/irrigation-plan/irrigation_settings.scss';
import './app/pages/water/irrigation-plan/irrigation_sites.scss';
import './app/pages/water/irrigation-plan/setPlanIrrigation-dialog.scss';
// import './app/pages/water/irrigation-plan/siteGraph-dialog.scss'
import './app/pages/water/irrigation-plans/irrigation-plans.scss';
import './app/pages/water/waterbudget/changeWeatherStation-dialog.scss';
import './app/pages/water/waterbudget/components/water-budget-month-list-table.scss';
import './app/pages/water/waterbudget/components/water-budget-projection-chart.scss';
import './app/pages/water/waterbudget/components/water-budget-summary-table.scss';
import './app/pages/water/waterbudget/updateMultiSites-dialog.scss';
import './app/pages/water/waterbudget/waterBudget.scss';
import './app/pages/water/waterbudgets/waterBudgets.scss';
import './app/pages/water/water-licence/water-licence.scss';
import './app/pages/water/water-licences/water-licences.scss';
import './app/quick-panel/quick-panel-alert.scss';
import './app/quick-panel/quick-panel.scss';
import './app/quick-panel/sleep-alerts-dialog.scss';
import './app/quick-panel/wake-slept-alert-dialog.scss';
import './app/toolbar/toolbar.scss';
import './app/react/three-slides.scss';

import './app/main';

import './app/base.controller';
import './app/index.run';
import './app/index.route';
import './app/index.controller';
import './app/fuse.module';
import './app/core/core.module';
import './app/core/directives/ms-navigation/ms-navigation.directive';
import './app/core/directives/ms-splash-screen/ms-splash-screen.directive';
import './app/core/directives/ms-stepper/ms-stepper.directive';
import './app/core/directives/ms-theme-options/ms-theme-options.directive';
import './app/core/directives/ms-widget/ms-widget.directive';
import './app/core/theming/fuse-palettes.constant';
import './app/core/theming/fuse-themes.constant';

import './app/_services/account/group-setting.service';
import './app/_services/account/report.service';
import './app/_services/account/site-setting.service';
import './app/_services/account/site.service';
import './app/_services/administration/accounts.service';
import './app/_services/administration/users.service';
import './app/_services/allocation.service';
import './app/_services/api.service';
import './app/_services/asset-class.service';
import './app/_services/auth-interceptor.service';
import './app/_services/auth-zero.service';
import './app/_services/colour-scheme.service';
import './app/_services/colour-set.service';
import './app/_services/crop.service';
import './app/_services/data-entity.service';
import './app/_services/day-number.service';
import './app/_services/decimal-day.service';
import './app/_services/dupe-handler.service';
import './app/_services/entity-list.service';
import './app/_services/equipment.service';
import './app/_services/fetch-data.service';
import './app/_services/filter.service';
import './app/_services/group-site.service';
import './app/_services/health-index.service';
import './app/_services/initialisation.service';
import './app/_services/irrigation-controller.service';
import './app/_services/language.service';
import './app/_services/local-storage.service';
import './app/_services/map.service';
import './app/_services/notifying.service';
import './app/_services/nutrients.service';
import './app/_services/nutrients/fertiliser.service';
import './app/_services/nutrients/nutrient.service';
import './app/_services/nutrients/profile.service';
import './app/_services/nutrients/program.service';
import './app/_services/nutrients/sample-point.service';
import './app/_services/permission.service';
import './app/_services/sensor-property.service';
import './app/_services/theming/fuse-theming.service';
import './app/_services/time.service';
import './app/_services/unit-of-measure.service';
import './app/_services/water.service';

import './app/_directives/calendar-readonly.directive';
import './app/_directives/decimal-step.directive';
import './app/_directives/display-units.directive';
import './app/_directives/draggable.directive';
import './app/_directives/lat-long.directive';
import './app/_directives/limit-to-max.directive';
import './app/_directives/limit-to-min.directive';
import './app/_directives/number-step.directive';
import './app/_directives/phone-required-pair.directive';
import './app/_directives/range-validator.directive';
import './app/_directives/unit-converter.directive';
import './app/_pipes/currency-name.pipe';
import './app/_pipes/date-locale.pipe';
import './app/_pipes/hour-minute.pipe';
import './app/_pipes/log-level.pipe';
import './app/_pipes/percent.pipe';
import './app/_pipes/provider-name.pipe';
import './app/_pipes/ticked.pipe';
import './app/_pipes/titlecase.pipe';
import './app/_pipes/translation-key.pipe';
import './app/_pipes/truncate.pipe';
import './app/_pipes/yesterday-by-day-number.pipe';

import './app/core/pipes/account.pipe';
import './app/core/pipes/convertTo.pipe';
import './app/core/pipes/convertUnits.pipe';
import './app/core/pipes/customer.pipe';
import './app/core/pipes/effective-period.pipe';
import './app/core/pipes/entity-list.pipe';
import './app/core/pipes/past-days-by-day-number.pipe';
import './app/core/pipes/selected-count.pipe';
import './app/core/pipes/table-filter.pipe';
import './app/core/pipes/today-and-future-by-day-number.pipe';

import './app/_components/crop-metric-icon';
import './app/_components/nutrients-summary-chart.component';
import './app/_components/nutrients-summary-table.component';
import './app/_components/select-account-sites.component';
import './app/_components/site-irrigation-summary-chart.component';
import './app/_components/status-dropdown.component';
import './app/_components/status-filter.component';
import './app/_components/status-icon';
import './app/_components/waterusage-summary-chart.component';
import './app/_components/weather-summary-chart.component';
import './app/_components/weather-summary-table.component';
import './app/_components/common/day-month-picker.component';
import './app/_components/common/groups-filter.component';
import './app/_components/common/run-data-capture.component';
import './app/_components/common/scheduler-times-component';
import './app/_components/common/sensor-property.component';
import './app/_components/common/site-picker.component';
import './app/_components/core/datatable-paging.component';
import './app/_components/nutrients/analyte-form.component';
import './app/_components/nutrients/nitrogen-form.component';
import './app/_components/nutrients/noncalculated-analyte-form.component';
import './app/_components/paged-logs.component';
import './app/_components/multirange/multirange.js';
import './app/_components/multirange/multirange.css';
import './app/_components/wsm-dashboard.component';

import './app/pages/account/components/nutrient-application.component';
import './app/pages/account/components/add-site-fertiliser-dialog.controller';
import './app/pages/account/components/apply-fertiliser-dialog.controller';
import './app/pages/account/components/change-date-confirm-dialog.controller';
import './app/pages/account/components/remove-fertiliser-dialog.controller';

import './app/navigation/navigation.module';
import './app/navigation/navigation.controller';

import './app/toolbar/toolbar.module';
import './app/toolbar/toolbar.controller';

import './app/quick-panel/quick-panel.module';
import './app/quick-panel/quick-panel.controller';
import './app/quick-panel/quick-panel-alert.component';

import './app/main.controller';

import './app/pages/auth/auth.module';
import './app/pages/auth/login/login.controller';

/* account */
import './app/pages/account/account.module';
import './app/pages/account/views/dashboard/colour-scheme-dialog.controller';
import './app/pages/account/views/dashboard/dashboard-health-index-table.component';
import './app/pages/account/views/dashboard/dashboard-help-dialog.controller';
import './app/pages/account/views/dashboard/dashboard.controller';
import './app/pages/account/views/equipment/equipment.controller';
import './app/pages/account/views/equipment/components/infra-flow-chart.component';
import './app/pages/account/views/equipment/components/infra-flow-table.component';
import './app/pages/account/views/equipment/components/infra-depth-chart.component';
import './app/pages/account/views/equipment/components/infra-depth-table.component';
import './app/pages/account/views/equipments/equipments.controller';
import './app/pages/account/views/group/groupSettings.controller';
import './app/pages/account/views/site/map/asset-property-view';
import './app/pages/account/views/site/map/map-control.controller';
import './app/pages/account/views/site/nutrients/reports.analytes.component';
import './app/pages/account/views/site/nutrients/reports.budgetvsactuals.component';
import './app/pages/account/views/site/nutrients/reports.component';
import './app/pages/account/views/site/nutrients/reports.fertiliser-application.component';
import './app/pages/account/views/site/nutrients/reports.nutrientactuals.component';
import './app/pages/account/views/site/nutrients/reports.tankmix.component';
import './app/pages/account/views/site/settingsTabs/kcvi/kcvi-settings.component';
import './app/pages/account/views/site/settingsTabs/weather/site-settings-weather.component';
import './app/pages/account/views/site/siteSettings.controller';
import './app/pages/account/views/site/waterTabs/site-water-history.component';
import './app/pages/account/views/site/waterTabs/soilmoisture-dialog.controller';
import './app/pages/account/views/sites/addsite-dialog.controller';
import './app/pages/account/views/sites/sites.controller';
import './app/pages/account/views/infrastructures/infrastructures.controller';
import './app/pages/account/views/infrastructure/infrastructure.controller';
import './app/pages/account/views/infrastructure/components/settings-depth-tab.component';

/* administration */
import './app/pages/administration/administration.module';
import './app/pages/administration/views/account-details/account-details.controller';
import './app/pages/administration/views/account-details/basic-dialog.controller';
import './app/pages/administration/views/account-details/data-capture/configure-account-assets.component';
import './app/pages/administration/views/account-details/data-capture/data-capture-dialog.controller';
import './app/pages/administration/views/account-details/kcvi/configureKcvi-options.component';
import './app/pages/administration/views/account-details/kcvi/configureKcvi-sites.component';
import './app/pages/administration/views/account-users/account-users.controller';
import './app/pages/administration/views/account-users/new-account-user-dialog.controller';
import './app/pages/administration/views/contacts/contacts.controller';
import './app/pages/administration/views/contacts/pricelist.controller';
import './app/pages/administration/views/references/dataagents/dataagent.controller';
import './app/pages/administration/views/system/accounts/accounts.controller';
import './app/pages/administration/views/system/users/users.controller';

/* water */
import './app/pages/water/water.module';
import './app/pages/water/allocations/allocations.controller';
import './app/pages/water/allocation/allocation.controller';
import './app/pages/water/waterbudgets/waterBudgets.controller';
import './app/pages/water/waterbudget/waterBudget.controller';
import './app/pages/water/waterbudget/components/water-budget-month-list-table.component';
import './app/pages/water/waterbudget/components/water-budget-projection-chart.component';
import './app/pages/water/waterbudget/components/water-budget-summary-table.component';
import './app/pages/water/irrigation-plans/irrigation-plans.controller';
import './app/pages/water/irrigation-plan/irrigation-plan.controller';

/* crops */
import './app/pages/crops/crops.module';
import './app/pages/crops/cycles/crop-cycles-chart/crop-cycles-chart.component';

/* nutrients */
import './app/pages/nutrients/nutrients.module';
import './app/_components/nutrients/analyte-form.component';
import './app/_components/nutrients/nitrogen-form.component';
import './app/_components/nutrients/noncalculated-analyte-form.component';
import './app/_components/nutrients/nutrients-form.component';
import './app/pages/nutrients/components/nutrient-sample-nitrogen.component';
import './app/pages/nutrients/components/nutrient-sample-nutrient.component';
import './app/pages/nutrients/programs/program-details/program-details.controller';
import './app/pages/nutrients/programs/program-details/tabs/dialogs/addNutrientPhase-dialog.component';
import './app/pages/nutrients/programs/program-details/tabs/distribution-profile.component';
import './app/pages/nutrients/programs/program-details/tabs/fertiliser-distribution.component';
import './app/pages/nutrients/programs/program-details/tabs/fertiliser-requirements.component';
import './app/pages/nutrients/programs/program-details/tabs/general-detail.component';
import './app/pages/nutrients/programs/program-details/tabs/planned-application.component';
import './app/pages/nutrients/programs/updatePrograms-dialog.controller';

/* profile */
import './app/pages/profile/profile.module';
import './app/pages/profile/tabs/profile-preferences.component';
import './app/pages/profile/tabs/profile-uom.component';

/* admin */
import './app/pages/administration/views/contacts/contacts.controller';
