import * as angular from 'angular';
import { Trace } from '@uirouter/angularjs';

angular.module('fuse').run([
  '$trace',
  ($trace: Trace) => {
    return new runBlock($trace);
  },
]);

class runBlock {
  constructor($trace: Trace) {
    //$trace.enable();
  }
}
