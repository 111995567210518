import { ILanguageService } from '@indicina/swan-shared/interfaces/ILanguageService';
import globalConfiguration from '@indicina/swan-shared/configuration/globalConfiguration';
import { TYPES } from '@indicina/swan-shared/types';
import { Container } from 'inversify';
import { AngularLanguageService } from './AngularLanguageService';

const container = new Container();

container.bind<ILanguageService>(TYPES.ILanguageService).to(AngularLanguageService);

globalConfiguration.container = container;
