import * as angular from 'angular';

export const coreModule = 'app.core';
export const appAuthModule = 'app.auth';
export const appNavigationModule = 'app.navigation';
export const appQuickPanelModule = 'app.quickPanel';
export const appToolbarModule = 'app.toolbar';
export const featureAccountModule = 'app.account';
export const featureAdminModule = 'app.administration';
export const featureCropsModule = 'app.crops';
export const featureNutrientsModule = 'app.nutrients';
export const featureProfileModule = 'app.profile';
export const featureWaterModule = 'app.water';

const coreModules = [
  coreModule,
];

const appModules = [
  appAuthModule,
  appNavigationModule,
  appQuickPanelModule,
  appToolbarModule,
];

const featureModules = [
  featureAccountModule,
  featureAdminModule,
  featureCropsModule,
  featureNutrientsModule,
  featureProfileModule,
  featureWaterModule,
];

const coreModuleDependencies = [
  'angular-jwt',
  'breeze.angular',
  'checklist-model',
  'jm.i18next',
  'ngAnimate',
  'ngAria',
  'ngCookies',
  'ngCsv',
  'ngFileUpload',
  'ngMaterial',
  'ngMessages',
  'ngSanitize',
  'rzSlider',
  //'server',
  'toastr',
  'ui.router',
  'vds.multirange',
];

//angular.module('server', []);

angular.module('fuse', [
  // NOTE: The order is important!
  ...coreModules,
  ...appModules,
  ...featureModules,
]);

// Core modules.
angular.module(coreModule, coreModuleDependencies);

// App modules.
angular.module(appAuthModule, []);
angular.module(appNavigationModule, []);
angular.module(appQuickPanelModule, []);
angular.module(appToolbarModule, []);

// Feature modules.
angular.module(featureAccountModule, ['720kb.tooltips']);
angular.module(featureAdminModule, []);
angular.module(featureCropsModule, []);
angular.module(featureNutrientsModule, []);
angular.module(featureProfileModule, []);
angular.module(featureWaterModule, []);
